import { Outlet, Link } from "react-router-dom";
import './navbar.css';
import woverlogo from './assets/Wover_logo.png';

const Navigation = () => {
  return (
    <>
      <nav>
        <ul className="navbarLi_Wrapper">
          <li className="navbarLi wover_Logo">
            <div className="wover_logo_wrapper"><img src={woverlogo}/></div>
            <Link to="/">wover.ai</Link>
          </li>
          <li className="navbarLi">
            <Link to="/">Home</Link>
          </li>
          <li className="navbarLi">
            <Link to="/careers">Careers</Link>
          </li>
          {/* <li className="navbarLi">
            <Link to="#about">About</Link>
          </li> */}
        </ul>
      </nav>

      <Outlet />
    </>
  )
};

export default Navigation;