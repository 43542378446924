import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import axios from 'axios';

function FormExample() {
  const [validated, setValidated] = useState(false);
  const[formdata,setData] = React.useState({username:"",userLastName:"",userAddress:"",title:"",companyName:"",description:"",termsChecked:0});
  const [res,setRes]=useState("");
  
  function submitFormData(event){
    // alert("submitFormData called");
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else{
      
    console.log(validated);
    setValidated(true);
    if(validated && formdata.termsChecked==="on"){
      var obj = new Object();
      obj.username = formdata.username;
      obj.userLastName = formdata.userLastName;
      obj.userAddress = formdata.userAddress;
      obj.title=formdata.title;
      obj.companyName=formdata.companyName;
      obj.termsChecked=formdata.termsChecked;
      obj.number = formdata.number;
      obj.description = formdata.description;
      console.log("entered data:"+JSON.stringify(obj));
    
    axios({
      method: 'post',
      headers:{
        "Content-Type": "application/json",
        "Acces-Control-Allow-Origin":"*"
      },
      url: 'https://9s6nte8j8g.execute-api.us-east-1.amazonaws.com/items',
      data: {
        username: formdata.username,
        useremail:formdata.userAddress,
        // userphone:formdata.number,
        // message:formdata.description
        userLastName :formdata.userLastName,
        title:formdata.title,
        companyName:formdata.companyName,
        termsChecked:formdata.termsChecked
      
      }
      
    })
    .then(function (response) {
      console.log(response);

      if (response.data){
        setRes("Details saved successfully. We will connect with you soon.");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
    }
      }
  }
  
  return (
    <Form noValidate validated={validated}>

      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <Form.Control  required
            type="text"
            placeholder="First name"
            value={formdata.username}
            onChange={(e)=>{ setData(prev=>({...prev,username:e.target.value})) }}
            
          />
          <Form.Control.Feedback type="invalid">Enter First Name.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom02">
          <Form.Label>Last name</Form.Label>
          <Form.Control type="text" placeholder="Last name"
          value={formdata.userLastName}
          onChange={(e)=>{setData(prev=>({...prev,userLastName:e.target.value}))}} 
          />
          <Form.Control.Feedback type="invalid">Enter Last Name.</Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom03">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" placeholder="Title"
          value={formdata.title}
          onChange={(e)=>{setData(prev=>({...prev,title:e.target.value}))}}
          />
          <Form.Control.Feedback type="invalid">
            Please provide tile or designation.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom04">
          <Form.Label>Company</Form.Label>
          <Form.Control type="text" placeholder="Company"
          value={formdata.companyName}
          onChange={(e)=>{setData(prev=>({...prev,companyName:e.target.value}))}} 
          />
          <Form.Control.Feedback type="invalid">
            Please provide company name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom05">
          <Form.Label>Business Email Address</Form.Label>
          <Form.Control type="email" placeholder="Email" value={formdata.userAddress} 
          onChange={(e)=>{setData(prev=>({...prev,userAddress:e.target.value}))}} 
          required />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email address.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check style={{color: "#fff"}} required
          checked={formdata.termsChecked}
          onChange={(e)=>{setData(prev=>({...prev,termsChecked:e.target.value}))}} 
          label="I consent to receiving email communications and marketing material from Wover.ai"
          feedback="You must agree before submitting."
          feedbackType="invalid"
        />
      </Form.Group>

      {/* <Button type="submit">Submit Request</Button> */}
      <Button variant="primary" onClick={submitFormData}>Submit Request</Button>
      {res && <p className='msgBlock'>{res}</p>}
    </Form>
  );
}

export default FormExample;