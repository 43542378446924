import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import linkedinIcon from './assets/linkedin.png';
import closeIcon from './close.png';
import axios from 'axios';
import About from './About';
import FormExample from './popup.js';
import { motion } from "framer-motion";

function PageSlide({ isOpen, onClose }) {
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  // function closeModal() {
  //   setShow(false)
  // }
  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: isOpen ? 0 : "100%" }}
      transition={{ duration: 0.3 }}
      style={{
        position: "fixed",
        top: 0, 
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "#000",
        color: "#fff",
        zIndex: 1000,
        padding: "20px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      }}
    >
      <div className='slide_container'>
        <h6 style={{fontFamily:"Nunito Sans", fontWeight:"normal"}}>Get Started</h6>
        <h1 className='slider_heading'>Get in touch with us.<br />We're here to assist you.</h1>
        <div onClick={onClose} className="closeBtn-wrapper-button closeBtn">
          <span>Close  &nbsp;</span><img src={closeIcon} alt='close button icon'/>
        </div>
        <a className="woverLinkIcon" href="https://www.linkedin.com/company/woverai/about" target="_blank"></a>
        <FormExample />
      </div>
    </motion.div>
    
  );
}

function Home() {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [res, setRes] = useState("");

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [formdata, setData] = React.useState({ username: "", userAddress: "", number: "", description: "" });
  // const [validated, setValidated] = useState(false);
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  function submitFormData(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {
      setValidated(true);

      var obj = new Object();
      obj.username = formdata.username;
      obj.userAddress = formdata.userAddress;
      obj.number = formdata.number;
      obj.description = formdata.description;

      axios({
        method: 'post',
        headers: {
          "Content-Type": "application/json",
          "Acces-Control-Allow-Origin": "*"
        },
        url: 'https://9s6nte8j8g.execute-api.us-east-1.amazonaws.com/items',
        data: {
          username: formdata.username,
          useremail: formdata.userAddress,
          userphone: formdata.number,
          message: formdata.description
        }

      })
        .then(function (response) {
          console.log(response);

          if (response.data) {

            setRes("Details saved successfully. We will connect with you soon.");

            setData({ username: "", userAddress: "", number: "", description: "" });

          }

        })
        .catch(function (error) {
          console.log(error);
        });
      //setData({username:"",userAddress:"",number:"",description:""});
    }
  }
  function closeModal() {
    setShow(false)
  }
  const [validated, setValidated] = useState(false);
  return (
    <div className='landing_page_background'>
      {/* <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">Wover.AI</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#careers">Careers</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
      </Navbar> */}
      <div className='betty-wrapper'>
        <h2>Unleash domain specific
          <br />
          Enterprise AI.</h2>
        <h4>Build your future employees now.</h4>
        <p>Generative AI Workflow Solutions that deliver Next-Level
          <br />performance and efficiency gains.</p>
        {values.map((v, idx) => (
          <Button key={idx} className="me-2 mb-2" onClick={() => setIsPopupOpen(true)}>
            Learn More
            {typeof v === 'string' && `below ${v.split('-')[0]}`}
          </Button>
        ))}
        <PageSlide isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />
      </div>
    </div>
  );
}

export default Home;
