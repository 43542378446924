import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './careers.css';
import { Container } from 'react-bootstrap';
import jobData from './data.json';


function Careers() {

  return (
    <div className='careers_wrapper'>
      <div>
        <h2 className='career_Heading_primary'>Join Our Team at Wover</h2>
        <h4 className='career_Heading_scondary'>Pioneering the future of automated workflow solutions!</h4>
        <p className='career_Heading_tertiary'>Why work with us?</p>
        <p className='career_description'>At Wover, we're redefining how businesses operate by streamlining processes through cutting-edge automation. Our mission is to<br></br>
          empower organizations to work smarter, faster, and more efficiently with our state-of-the-art automated workflow builder. if you're<br></br> passionate about innovation, problem-solving, and making a tangible impact, this is the place for you.
        </p>
      </div>

      <div className='section_heading'>
        <h2>Careers at Wover</h2>
      </div>
      
      <div className='grid_Row'>
      {jobData.map((item)=>
      <div className='grid_Column'>
      <div className='career_Label'>
        <div className='career_Icon'></div>
        <div className='career_Type'>FULL TIME</div>
      </div>
      <h4>{item.job_title}</h4>
      <p>{item.job_description}</p>
      <div className='details_Icon'><a href={item.job_link} target='_blank'>More Details</a></div>
    </div>
      )}
        
      </div>
      
      <div className='container_footer'>
        <div className='footer_content'></div>
        <div className='footer_content'>
          <h1>Reach out at HR</h1>
        </div>
        <div className='footer_content'>
          <p>Not finding anything special opportunity<br></br>
            Have questions about career opportunities at Wover?<br></br>
            Reach out at <a href="mailto:hr@wover.ai">hr@wover.ai</a></p>
        </div>
      </div>
    </div>
  );
}

export default Careers;
