import React, { useState } from 'react';
//import './App.css';
import './about.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


function About() {
  
  return (
    <div className='about-wover-page'>
     <Container>
      <Row className='aboutInnerWrapper'>
        <Col>
            <h4 className='AboutLabel'>Contact Info</h4>
            <h2>We are always happy <br/>to assist you</h2>
        </Col>
        <Col className='aboutLabelContent'>
            <h4 className='AboutLabel'>Email Address</h4>
            <p>___</p>
            <p>sales@wover.ai</p>
            <p>2755 Augustine Drive Santa Clara CA 95054</p>
        </Col>
        <Col className='aboutLabelContent'><h4 className='AboutLabel'>Number</h4>
            <p>___</p>
            <p>(808) 998-34256</p>
            <p>Assistance hours: Monday - Friday 6 am to 8 pm EST</p>
        </Col>
      </Row>
    </Container>
    <div className='fullWidth'>
        <h4 className='teamsLabel'>Our Teams</h4>
        <p className='teamsTagline'>Some of the people<br/> you will be working with</p>
        <div className='colMd4'>
            <div><img/></div>
            <p className='aboutName'>Mehmedi Ahmad</p>
            <p className='aboutDesc'>Creative Director</p>
        </div>
        <div className='colMd4'>
            <div><img/></div>
            <p className='aboutName'>Putri Angelina</p>
            <p className='aboutDesc'>Assistant Director</p>
        </div>
        <div className='colMd4'>
            <div><img/></div>
            <p className='aboutName'>Jason Alexander</p>
            <p className='aboutDesc'>Project Manager</p>
        </div>
        <div className='colMd4'>
            <div><img/></div>
            <p className='aboutName'>Alex Martinus</p>
            <p className='aboutDesc'>Head Officer</p>
        </div>
        
    </div>
    <div className='fullWidth'>
        <div className='colMd4'>
            <div><img/></div>
            <p className='aboutName'>Williamson Andre</p>
            <p className='aboutDesc'>Assistant Manager</p>
        </div>
        <div className='colMd4'>
            <div><img/></div>
            <p className='aboutName'>Anderson Eder</p>
            <p className='aboutDesc'>Designer</p>
        </div>
        <div className='colMd4'>
            <div><img/></div>
            <p className='aboutName'>Douglas Packer</p>
            <p className='aboutDesc'>Programmer</p>
        </div>
        <div className='colMd4'>
            <div><img/></div>
            <p className='aboutName'>Thomas Johnson</p>
            <p className='aboutDesc'>Supervisor</p>
        </div>
    </div>
    <div className='fullWidth_section'>
        <div className='colMd50'>
            <h4>Wover.AI</h4>
            <a className="woverLinkIcon" href="https://www.linkedin.com/company/woverai/about" target="_blank"></a>
            <p>Enterprise Services Blog</p>
            <div>
                <input type="text" placeholder='Search'/>
                <button>Search</button>
            </div>
        </div>
        <div className='colMd20'>
            <h4>Product</h4>
            <ul>
                <li>OverView</li>
                <li>Features</li>
                <li>Solutions</li>
                <li>Tutorials</li>
                <li>Pricing</li>
            </ul>
        </div>
        <div className='clcolMd30'>
            <h4>Company</h4>
            <ul>
                <li>About Us</li>
                <li>Careers</li>
                <li>Blogs</li>
                <li>Help Centre</li>
                <li>Events</li>
            </ul>            
        </div>
    </div>
    <div className='fullWidth_footer'>
        <div className='colMd10'>
            <a>Terms & Condition</a>
        </div>
        <div className='colMd70'>
            <a>Privacy Policy</a>   
        </div>
        <div className='colMd20_footer'>
            <p>Copyright Wover.AI All Rights Reserved</p>  
        </div>
    </div>
    </div>
  );
}

export default About;
